import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import createLogger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import rootReducers from '../reducers';

const loggerMiddleware = createLogger;

export default function configureStore(preloadedState: any) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducers, preloadedState, applyMiddleware(sagaMiddleware));

  sagaMiddleware.run(rootSaga);

  return store;
}
