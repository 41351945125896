import React from 'react'

type Props = {
    className: string;
    alignItems?: string;
    flexDirection: string;
    justifyContent?: string;
    mobileFlex: boolean;
    children: any;
}

const Flex = (props: Props) => {
    const { children, className, alignItems, justifyContent, mobileFlex, flexDirection } = props
    const getFlexResponsive = () => mobileFlex ? 'd-flex' : 'd-md-flex'
    return (
        <div className={`${getFlexResponsive()} ${className} ${flexDirection ? ('flex-' + flexDirection) : ''} ${alignItems ? ('align-items-' + alignItems) : ''} ${justifyContent ? ('justify-content-' + justifyContent) : ''}`}>
            {children}
        </div>
    )
}

Flex.defaultProps = {
    mobileFlex: true,
    flexDirection: 'row',
    className: ''
};


export default Flex
