import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  FETCH_USER,
  LOGIN,
  FAIL_FETCHING_USER,
  CLICK_LOGOUT,
  LOGOUT
} from '../constants/Auth';

import { createAction } from 'redux-act';

export const fetchLoginState = createAction<any | undefined>('fetch login state');
export const failFetchingLoginState = createAction<any | undefined>('fail fetching login state');

export const fetchUser = createAction<any | {}>(FETCH_USER);
export const failFetchingUser = createAction<any | undefined>(FAIL_FETCHING_USER);

export const login = createAction<any | undefined>(LOGIN);
export const clickLogout = createAction(CLICK_LOGOUT);
export const logout = createAction(LOGOUT);

export const failFetchingApi = createAction<any | undefined>('fail fetching api');

export const hello = createAction<any | undefined>('hello');

export const fetchHello = createAction<any | undefined>('fetch hello');

export const signIn = (user: any) => {
  return {
    type: SIGNIN,
    payload: user
  };
};

export const authenticated = (token: any) => {
  return {
    type: AUTHENTICATED,
    token
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS
  };
};

export const signUp = (user: any) => {
  return {
    type: SIGNUP,
    payload: user
  };
};

export const signUpSuccess = (token: any) => {
  return {
    type: SIGNUP_SUCCESS,
    token
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE
  };
};

export const signInWithGoogleAuthenticated = (token: any) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK
  };
};

export const signInWithFacebookAuthenticated = (token: any) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token
  };
};

export const showAuthMessage = (message: any) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING
  };
};
