import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./containers";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/appConfig";
import "./asserts/less/index.less";
import "./asserts/less/dark-theme.less";
import "./asserts/less/light-theme.less";
import "./asserts/less/styles/styles.less";
import { fetchLoginState } from "./redux/actions/Auth";
// import './App.css';
// import './asserts/css/index.css';
// import './asserts/css/dark-theme.css';
// import './asserts/css/light-theme.css';
// import './asserts/css/layout.css';

const themes = {
  dark: `./asserts/css/dark-theme.css`,
  light: `./asserts/css/light-theme.css`,
};

function App() {
  const dispatch = useDispatch();
  const authData = useSelector((state: { auth: any }) => state.auth);

  useEffect(() => {
    const { pathname } = document.location;

    dispatch(fetchLoginState({ pathname: pathname }));
    console.log("App:authdata", authData);
  }, []);

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </ThemeSwitcherProvider>
    </div>
  );
}

export default App;
