import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />

type Props = {
    size?: string;
    cover: string;
    align: string;

}

const defaultProps: Props = {
    align: 'center',
    cover: 'inline'
}

const Loading = (props: Props) => {
    const { align, cover } = props
    return (
        <div className={`loading text-${align} cover-${cover}`}>
            <Spin indicator={Icon} />
        </div>
    )
}

Loading.defaultProps = defaultProps;

export default Loading