import React, { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from "../../components/common/Loading";
import { APP_PREFIX_PATH } from "../../configs/appConfig";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { notification } from "antd";
import useSound from "use-sound";
import boopSfx from "../../asserts/sounds/notification.mp3";

const websocket = new W3CWebSocket(
  `${
    process.env.REACT_APP_ENV === "dev"
      ? process.env.REACT_APP_DEVICE_WEBSOCKET_DEV
      : process.env.REACT_APP_DEVICE_WEBSOCKET_QA
  }`
);

export const AppViews = () => {
  let history = useHistory();
  const authData = useSelector((state: { auth: any }) => state.auth);
  const [play] = useSound(boopSfx);

  useEffect(() => {
    if (authData.isPrepared && !authData.isLoggedIn) {
      history.push("/auth");
    }

    websocket.onopen = () => {
      console.debug("WebSocket Client Connected");
    };

    websocket.onmessage = (message) => {
      console.debug(message);
      const ms = JSON.parse(message.data);
      if (ms.type === "active-device-notification") {
        play();
        openNotificationWithIcon(ms.guid, ms.vehicleNumber);
      }
    };
  }, [authData, history]);

  const openNotificationWithIcon = (guid: string, vehicleNumber: string) => {
    notification["success"]({
      message: "Device Activation",
      description: `${guid} is activated`,
    });
  };

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/devices/:id`}
          component={lazy(() => import(`./devices/device`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/devices`}
          component={lazy(() => import(`./devices`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/healthCheck`}
          component={lazy(() => import(`./healthCheck/healthCheck`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/devices`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
