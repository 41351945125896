import { 
  DashboardOutlined,
  ApiOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from './appConfig';

const dashBoardNavTree = [
{
  key: 'devices',
  path: `${APP_PREFIX_PATH}/devices`,
  title: 'Products',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
},
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
