import { combineReducers } from 'redux';
import Auth from './auth';
import Theme from './Theme';

const reducers = combineReducers({
  auth: Auth,
  theme: Theme
});

export default reducers;
